import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, firstValueFrom, map, Observable, of, Subject, tap, throwError } from 'rxjs';

import { ResponseWrapper } from '@@core/models/api.model';
import { AppBranches } from '@@core/models/app-branches.model';
import { AppsBranchesCodeLabelsFilter, AppsBranchesFilter } from '@@core/models/apps-branches-filter.model';
import { Build, BuildComponentCoverage } from '@@core/models/build/build.model';
import { BuildFile } from '@@core/models/build/build-file.model';
import { BuildHistoryItem } from '@@core/models/build/build-history.model';
import { BuildMetrics } from '@@core/models/build/build-metrics.model';
import { BuildsFilter, BuildsFilterCodeLabelsCategory } from '@@core/models/build/builds-filter.model';
import { FileCodeElements } from '@@core/models/build/file-code-elements.model';
import { ALL_TEST_STAGES, TestStage, TestStageMetrics } from '@@core/models/build/test-stage.model';
import { CodeLabelsCategory } from '@@core/models/code-labels-category.model';
import { QualityGate } from '@@core/models/quality-gate/quality-gate.model';
import { ApiService, PagedApiRequestParams } from '@@shared/common';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { CodeScopeAppRule, CodeScopeAppRuleCreateBody } from '../models/code-scope.model';
import { TestStageStatuses } from '../models/dashboard.model';
import { DashboardFilter, DashboardFilterCodeLabelsCategory, DashboardViewRequest, SaveViewResponse } from '../models/dashboard-filter.model';
import { DashboardViewNameExistResponse, ViewsResponse } from '../models/views.model';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	dashboardBuilds$ = new BehaviorSubject<Build[]>([]);
	dashboardBuildsLoaded$ = new BehaviorSubject<boolean>(false);
	dashboardBuildTestStages$ = new BehaviorSubject<TestStage[]>([]);
	dashboardBuildHistory$ = new BehaviorSubject<BuildHistoryItem[]>(null);
	buildFiles$ = new BehaviorSubject<BuildFile[]>([]);
	buildFilesLoaded$ = new BehaviorSubject<boolean>(false);
	fileCodeElelments$ = new BehaviorSubject<FileCodeElements>(null);
	codeLabelCategories$ = new BehaviorSubject<CodeLabelsCategory[]>([]);
	appsBranches$ = new BehaviorSubject<AppBranches[]>([]);
	dashboardFilter$ = new BehaviorSubject<DashboardFilter>({});
	coverageType$ = new BehaviorSubject<string>('method');
	qualityGate$ = new BehaviorSubject<QualityGate>(null);
	dashboardSerchTerm$ = new BehaviorSubject<string>('');
	testStagesRetrieved$ = new Subject<boolean>();

	readonly #httpClient = inject(HttpClient);
	readonly #apiService = inject(ApiService);
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;

	set dashboardSearchTerm(searchTerm: string) {
		this.dashboardSerchTerm$.next(searchTerm);
	}

	saveFilter(filter: DashboardFilter): void {
		this.dashboardFilter$.next(filter);
	}

	/**
	 * Get a single build
	 * @param bsid:
	 */
	getBuild(bsid: string): Promise<Build> {
		const apiUrl = `${this.#apiBaseUrl}v1/builds/${encodeURIComponent(bsid)}`;

		return firstValueFrom(this.#httpClient.get<Build>(apiUrl));
	}

	/**
	 * Get builds and their coverage metrics by filter
	 */
	loadBuilds(search = '', page = 1, pageSize = 50): void {
		const dashboardFilter = this.dashboardFilter$.value;
		const appBranchesFilter = dashboardFilter?.appBranches ? { appBranches: dashboardFilter.appBranches } : {};

		const apiUrl = `${this.#apiBaseUrl}v2/builds/filter`;
		let httpParams = new HttpParams().append('paging', [page, pageSize].toString());

		if (search) {
			httpParams = httpParams.append('searchTerm', search);
		}

		if (dashboardFilter?.codeLabels?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsDashboardQueryParam(dashboardFilter.codeLabels));
		}


		this.#httpClient.post<Build[]>(apiUrl, appBranchesFilter, { params: httpParams })
			.subscribe(response => {
				const bsids = response.map(element => element.build.bsid);

				this.getTiaStatuses(bsids)
					.pipe(
						tap(statuses => {
							response.forEach(element => element.build.tiaStatuses = statuses.find(status => status.bsId === element.build.bsid)?.statuses);
						}),
						finalize(() => {
							if (page > 1) {
								this.dashboardBuilds$.next([...this.dashboardBuilds$.value, ...response]);
							} else {
								this.dashboardBuilds$.next(response);
							}
							this.dashboardBuildsLoaded$.next(true);

							response.forEach(element => {
								// we convert because the model is numeric but the server returns a timestamp string
								element.build.generated = new Date(element.build.generated).valueOf();
								if (element.refBuild) {
									element.refBuild.generated = new Date(element.refBuild.generated).valueOf();
								}

								this.getBuildMetrics(element.build.bsid, dashboardFilter.codeLabels)
									.pipe(
										catchError(() => of({
											bsid: element.build.bsid,
											qrs: {},
											coverage: {},
											modifiedCodeCoverage: {},
											isHighPriority: false
										})),
										tap(metrics => {
											element.metrics = metrics;
										})
									).subscribe();
							});
						})
					).subscribe();
			});
	}

	/**
	 * Compose id-based code labels filter from name-based code labels filter
	 * @param filter name-based code labels filter
	 */
	composeCodeLabelsDashboardQueryParam(filter: DashboardFilterCodeLabelsCategory[]): string {
		return filter.map(item => item.labels.map(label => `${item.category}$${label}`).join(',')).join(',');
	}

	resetDashboardBuilds(): void {
		this.dashboardBuilds$.next([]);
		this.dashboardBuildsLoaded$.next(false);
	}

	/**
	 * Get a single build history builds and their coverage metrics
	 * @param bsid:
	 */
	loadBuildHistory(build: Build, page = 1, pageSize = 15): void {
		const apiUrl = `${this.#apiBaseUrl}v1/build-history/${encodeURIComponent(build.build.bsid)}`;
		const httpParams = new HttpParams().set('paging', `${page},${pageSize}`);

		const filter = this.dashboardFilter$.value || {};

		if (page === 1) {
			this.dashboardBuildHistory$.next(null);
		}

		this.#httpClient.get<BuildHistoryItem[]>(apiUrl, { params: httpParams })
			.subscribe(response => {
				response.forEach(item => item.generated = new Date(item.generated).valueOf());
				this.dashboardBuildHistory$.next([...this.dashboardBuildHistory$.value || [], ...response]);
				response.forEach(element => {
					this.getBuildMetrics(element.bsid, filter.codeLabels)
						.subscribe({
							next: metrics => {
								element.metrics = metrics;
							},
							error: () => {
								element.metrics = {
									bsid: build.build.bsid,
									qrs: {},
									coverage: {},
									modifiedCodeCoverage: {},
									modifiedTotals: {},
									overallLineCoverage: null,
									isHighPriority: false
								};
							}
						});
				});
			});
	}

	/**
	 * Get a single build coverage metrics
	 * @param bsid:
	 * @param filter:
	 */
	getBuildMetrics(bsid: string, filter: DashboardFilterCodeLabelsCategory[]): Observable<BuildMetrics> {
		const apiUrl = `${this.#apiBaseUrl}v2/coverage/builds/${encodeURIComponent(bsid)}`;
		let httpParams = new HttpParams();

		if (filter?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsDashboardQueryParam(filter));
		}

		return this.#httpClient.get<BuildMetrics>(apiUrl, { params: httpParams });
	}

	/**
	 * Get a single build test stages or a specific test stage, and coverage metrics
	 * @param bsid:
	 * @param testStage:
	 */
	loadTestStages(bsid: string, testStage?: string): void {
		this.testStagesRetrieved$.next(false);
		const apiUrl = testStage
			? `${this.#apiBaseUrl}v1/test-stages/${encodeURIComponent(bsid)}?testStage=${encodeURIComponent(testStage)}`
			: `${this.#apiBaseUrl}v1/test-stages/${encodeURIComponent(bsid)}`;

		const filter = this.dashboardFilter$.value || {};

		this.dashboardBuildTestStages$.next([]);
		this.#httpClient.get<TestStage[]>(apiUrl)
			.subscribe({
				next: response => {
					this.testStagesRetrieved$.next(true);
					response.forEach(element => {
						if (element.testStage) {
							element.isEntireBuild = false;
						} else {
							element.testStage = ALL_TEST_STAGES;
							element.isEntireBuild = true;
						}

						element.testCount.total = element.testCount.passed + element.testCount.failed + element.testCount.skipped;
					});
					this.dashboardBuildTestStages$.next(response);
					response.filter(element => !element.isEntireBuild).forEach(element => {
						this.getTestStageMetrics(element.buildSessionId, element.testStage, filter.codeLabels)
							.subscribe({
								next: metrics => {
									element.metrics = metrics;
									this.dashboardBuildTestStages$.next([...response]);
								},
								error: () => {
									element.metrics = {
										testStage: element.testStage,
										qrs: {},
										coverage: {},
										modifiedCodeCoverage: {}
									};
									this.dashboardBuildTestStages$.next([...response]);
								}
							});
					});
				},
				error: () => {
					const allTestStages = {
						testStage: ALL_TEST_STAGES,
						isEntireBuild: true,
					} as TestStage;

					this.testStagesRetrieved$.next(true);
					this.dashboardBuildTestStages$.next([allTestStages]);
				}
			});
	}

	/**
	 * Get a single test stage coverage metrics
	 * @param bsid:
	 * @param testStage:
	 * @param filter:
	 */
	getTestStageMetrics(bsid: string, testStage: string, filter?: DashboardFilterCodeLabelsCategory[]): Observable<TestStageMetrics> {
		const apiUrl = `${this.#apiBaseUrl}v2/coverage/builds/${encodeURIComponent(bsid)}/test-stages/${encodeURIComponent(testStage)}`;
		let httpParams = new HttpParams();

		if (filter?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsDashboardQueryParam(filter));
		}

		return this.#httpClient.get<TestStageMetrics>(apiUrl, { params: httpParams });
	}

	downloadBuild(build: Build): Observable<{ fileName: string; buffer: ArrayBuffer }> {
		const apiUrl = `${this.#apiBaseUrl}v1/coverage/builds/csv`;
		const body = { bsids: [build.build.bsid] };

		const result = this.#httpClient.post(apiUrl, body, { responseType: 'arraybuffer', observe: 'response' })
			.pipe(
				map(response => {
					const fileName = `${build.appName}.csv`;

					return { fileName, buffer: response.body };
				}),
				catchError(error => throwError(() => new Error(error)))
			);

		return result;
	}

	/**
	 * Get files of a single build/test stage, by filter
	 * @param bsid:
	 * @param testStage:
	 * @param filter:
	 */
	loadBuildFiles(bsid: string, testStage?: string, filter?: DashboardFilterCodeLabelsCategory[]): void {
		const apiUrl = `${this.#apiBaseUrl}v2/coverage/builds/${encodeURIComponent(bsid)}/files`;
		let httpParams = new HttpParams();

		if (testStage) {
			httpParams = httpParams.append('testStage', testStage);
		}

		if (filter?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsDashboardQueryParam(filter));
		}

		this.#httpClient.get<BuildFile[]>(apiUrl, { params: httpParams })
			.subscribe(response => {
				this.buildFiles$.next(response);
				this.buildFilesLoaded$.next(true);
			});
	}

	/**
	 * Get all code elements of a single file
	 * @param bsid:
	 * @param fileId:
	 * @param testStage:
	 * @param filter:
	 */
	loadFileCodeElements(bsid: string, fileId: string, testStage?: string, filter?: DashboardFilterCodeLabelsCategory[]): void {
		const apiUrl = `${this.#apiBaseUrl}v2/coverage/builds/${encodeURIComponent(bsid)}/files/${encodeURIComponent(fileId)}`;
		let httpParams = new HttpParams();

		if (testStage) {
			httpParams = httpParams.append('testStage', testStage);
		}

		if (filter?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsDashboardQueryParam(filter));
		}


		this.#httpClient.get<FileCodeElements>(apiUrl, { params: httpParams })
			.subscribe(response => this.fileCodeElelments$.next(response));
	}

	/**
	 * Get all code labels
	 */
	loadCodeLabelsCategories(): Promise<ResponseWrapper<CodeLabelsCategory[]>> {
		const apiUrl = `${this.#apiBaseUrl}v2/settings/codeLabelCategories`;

		return firstValueFrom(this.#httpClient.get<ResponseWrapper<CodeLabelsCategory[]>>(apiUrl)
			.pipe(tap(response => {
				this.codeLabelCategories$.next(response.data);
			})));
	}

	/**
	 * Get apps/branches by filter
	 * @param filter:
	 */
	loadAppsBranches(filter: AppsBranchesFilter): void {
		const apiUrl = `${this.#apiBaseUrl}v2/app-branches`;
		let httpParams = new HttpParams();

		if (filter?.codeLabels?.length) {
			httpParams = httpParams.append('ids', this.composeCodeLabelsFilterQueryParam(filter.codeLabels));
		}

		this.#httpClient.get<AppBranches[]>(apiUrl, { params: httpParams })
			.subscribe(response => this.appsBranches$.next(response));
	}

	/**
	 * Compose id-based code labels filter from name-based code labels filter
	 * @param filter name-based code labels filter
	 */
	composeCodeLabelsFilterQueryParam(filter: AppsBranchesCodeLabelsFilter[]): string {
		return filter.map(item => item.labels.map(label => `${item.category}$${label}`).join(',')).join(',');
	}

	/**
	 * Change current coverage type (determines the metrics displayed)
	 * @param coverageType:
	 */
	changeCoverageType(coverageType: string): void {
		this.coverageType$.next(coverageType);
	}

	/**
	 * Convert id-based code labels filter to name-based filter
	 * Filter in user preferences is saved by id, but the rest of the API endpoints expect name-based filter
	 * @param codeLabelsIdsFilter:
	 */
	composeCodeLabelsFilter(codeLabelsIdsFilter: BuildsFilterCodeLabelsCategory[]): DashboardFilterCodeLabelsCategory[] {
		const codeLabelsFilter: DashboardFilterCodeLabelsCategory[] = [];

		if (codeLabelsIdsFilter) {
			codeLabelsIdsFilter.forEach(filterCategory => {
				const category = this.codeLabelCategories$.value.find(stockCategory => stockCategory.id === filterCategory.category);

				if (category) {
					const labels = filterCategory.labels.map(labelId => (category.labels || []).find(label => label.id === labelId).id);

					codeLabelsFilter.push({
						category: category.id,
						labels
					});
				}
			});
		}

		return codeLabelsFilter;
	}

	removeOrphansFromDashboardFilter(filter: BuildsFilter): BuildsFilter {
		const cleanFilter: BuildsFilter = {};
		const cleanCodeLabelsFilter: BuildsFilterCodeLabelsCategory[] = [];

		filter?.codeLabels?.forEach(item => {
			const stockCategory = this.codeLabelCategories$.value.find(category => category.id === item.category);

			if (stockCategory) {
				item.labels = item.labels.filter(labelId => stockCategory?.labels.some(label => label.id === labelId));

				if (item.labels.length) {
					cleanCodeLabelsFilter.push(item);
				}
			}
		});

		if (filter.appBranches) {
			cleanFilter.appBranches = [...filter.appBranches];
		}
		if (cleanCodeLabelsFilter.length) {
			cleanFilter.codeLabels = cleanCodeLabelsFilter;
		}

		return cleanFilter;
	}

	/**
	 * Set/clear reference build flag
	 * @param appName:
	 * @param branchName:
	 * @param buildName:
	 * @param flag:
	 */
	setReferenceBuild(appName: string, branchName: string, buildName: string, flag: boolean): Promise<any> {
		const encodedParams = {
			appName: encodeURIComponent(appName),
			branchName: encodeURIComponent(branchName),
			buildName: encodeURIComponent(buildName)
		};
		const apiUrl = `${this.#apiBaseUrl}v2/builds/${encodedParams.appName}/${encodedParams.branchName}/${encodedParams.buildName}`;
		const body = {
			deployed: flag
		};

		return firstValueFrom(this.#httpClient.put(apiUrl, body));
	}


	getTiaStatuses(buildSessionIds: string[]): Observable<TestStageStatuses[]> {
		const url = `${this.#apiBaseUrl}v1/tia/test-stages/status`;

		return this.#httpClient.post<TestStageStatuses[]>(url, { buildSessionIds });
	}

	createCodeScopeAppRule(body: CodeScopeAppRuleCreateBody): Observable<CodeScopeAppRule> {
		const api = `${this.#apiBaseUrl}v2/settings/scoped-rules`;

		return this.#httpClient.post<ResponseWrapper<CodeScopeAppRule>>(api, body)
			.pipe(
				map(response => response.data)
			);
	}

	calculateCoverage(buildBsid: string, testStage: string): Observable<any> {
		const api = `${this.#apiBaseUrl}v1/calculate-now/bsid/${encodeURIComponent(buildBsid)}/test-stage/${encodeURIComponent(testStage)}`;
		return this.#httpClient.post<ResponseWrapper<CodeScopeAppRule>>(api, {});
	}

	getBuildComponents(bsid: string): Observable<ResponseWrapper<BuildComponentCoverage>> {
		const api = `${this.#apiBaseUrl}v2/coverage/builds/${encodeURIComponent(bsid)}/components`;
		return this.#httpClient.get<ResponseWrapper<BuildComponentCoverage>>(api);
	}

	saveDashboardView(view: DashboardViewRequest): Observable<SaveViewResponse> {
		const api = `${this.#apiBaseUrl}v1/saved-views`;
		return this.#httpClient.post<SaveViewResponse>(api, view);
	}

	deleteDashboardView(viewId: string): Observable<void> {
		const api = `${this.#apiBaseUrl}v1/saved-views/${encodeURIComponent(viewId)}`;
		return this.#httpClient.delete<void>(api);
	}

	getDashboardViews(params: PagedApiRequestParams): Observable<ViewsResponse> {
		const httpParams = this.#apiService.createPagedApiHttpParams(params);
		const url = `${this.#apiBaseUrl}v1/saved-views`;

		return this.#httpClient.get<ViewsResponse>(url, { params: httpParams });
	}

	getDashboardViewByName(viewName: string): Observable<DashboardViewNameExistResponse> {
		const url = `${this.#apiBaseUrl}v1/saved-views/name-exists/${encodeURIComponent(viewName)}`;
		return this.#httpClient.get<ResponseWrapper<DashboardViewNameExistResponse>>(url)
			.pipe(
				map(response => response.data)
			);
	}
}
